import React, {useState } from 'react'
import 'rc-slider/assets/index.css';
import { SlArrowDown } from "react-icons/sl";
import { RangSlider } from '../rangSlider/RangSlider';
import { useDispatch } from 'react-redux';
import { fetchSelectedData } from '../../redux/ProductSlice';
import { IoIosSearch } from "react-icons/io";

const Sidebar = ({sidebarData}) => {


    const dispatch = useDispatch()

    const [selectValue, setSelectValue] = useState({
        city: "", 
        bhk: "", 
        segment: "",
        
    });
    
    
    const handleSelect = (e) => {
        const { name, value } = e.target;
        setSelectValue((prevValues) => ({ ...prevValues, [name]: value }));
       
    };
    
    const handleSubmit = (e) => {
        debugger
      e.preventDefault()
        console.log('city value',selectValue )
        dispatch(fetchSelectedData(selectValue));
 
    };

    
    const cityName = [...new Set(sidebarData?.map((item)=> item.city))]
    const bhk = [...new Set(sidebarData?.map((item)=> item.unit_type))]
    const segment = [...new Set(sidebarData?.map((item)=> item.segment))]
    const price = [...new Set(sidebarData?.map((item)=> item.price))]
    console.log('cityName', cityName)


  return (
    <>
       <div className='sidebar mt-3 py-3'>
            <div className='container'>
               <form onSubmit={handleSubmit}> 
                    <div className='search-box'>
                        <div className='form-item'>
                            <div className='search_drop'>
                                {/* <input type='button' value="Destination" /> */}
                                <SlArrowDown />
                                <select id="city-select" value={selectValue.city} name="city" onChange={handleSelect}>

                                <option value="">Location</option>
                                    {
                                        
                                        cityName?.map((item, index)=>{
                                            return(
                                                <>
                                                    <option key="index">{item}</option>
                                                </>
                                            )
                                        })
                                    }
                                </select>
                                {/* <div className='select-drop'>
                                
                                    <ul className='m-0 p-0'>
                                        {
                                            sidebarData.map((item, index)=>{
                                                return(
                                                    <>
                                                        <li key={index}>{item.city}</li>
                                                    </>
                                                )
                                            })
                                        }
                                        
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className='search-box'>
                        <div className='form-item'>
                            <div className='search_drop' >
                                {/* <input type='button' value="BHK" /> */}
                                <SlArrowDown />
                                <select id="bhk" name="bhk" value={selectValue.bhk} onChange={handleSelect}>

                                <option value="">BHK</option>
                                    {
                                        
                                        bhk?.map((item, index)=>{
                                            return(
                                                <>
                                                    <option key="index">{item}</option>
                                                </>
                                            )
                                        })
                                    }
                                </select>
                                {/* <div className='select-drop'>
                                    <ul className='m-0 p-0'>
                                        {
                                            sidebarData.map((item, index)=>{
                                                return(
                                                    <>
                                                        <li key={index}>{item.unit_type}</li>
                                                    </>
                                                )
                                            })
                                        }
                                    
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className='search-box'>
                        <div className='form-item'>
                            <div className='search_drop' >
                                {/* <input type='button' value="Property Type" /> */}
                                <SlArrowDown />
                                <select id="segment" name="segment" value={selectValue.segment} onChange={handleSelect}>

                                <option value="">Property Type</option>
                                    {
                                        
                                        segment?.map((item, index)=>{
                                            return(
                                                <>
                                                    <option key="index">{item}</option>
                                                </>
                                            )
                                        })
                                    }
                                </select>
                                {/* <div className='select-drop'>
                                    <ul className='m-0 p-0'>
                                        {
                                            sidebarData.map((item, index)=>{
                                                return(
                                                    <>
                                                        <li>
                                                            <div class="form-group">
                                                                <input type="checkbox" id="apartment" />
                                                                <label htmlFor="apartment">{item.segment}</label>
                                                            </div>
                                                        </li>
                                                    </>
                                                )
                                            })
                                        }
                                        
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className='search-box'>
                        <div className='form-item'>
                            <div className='search_drop'>
                                {/* <input type='button' value="Budget" /> */}
                                <SlArrowDown />
                                <select id="state-select" name="price" value={selectValue.price} onChange={handleSelect}>
                                
                                    <option value="">Budget</option>
                                    {
                                        price?.map((item, index)=>{
                                            return(
                                                <>
                                                    <option key="index">{item}</option>
                                                </>
                                            )
                                        })
                                    }
                                    {/* <RangSlider  value={priceRange} setValue={setPriceRange} /> */}
                                </select>
                                {/* <div className='select-drop'>
                                    <RangSlider price={price}/>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className='search-box'>
                        <div className='submit-btn'>
                            <button type='submit'><IoIosSearch /></button>
                        </div>
                    </div>
               </form>
            </div>
       </div>
    </>
  )
}

export default Sidebar