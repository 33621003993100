import React from 'react'

const SiteLoader = () => {
  return (
    <div className="site-loader">
        <div className="spinner"></div>
    </div>
  )
}

export default SiteLoader