import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../images/logo.png'
import { IoMdArrowForward } from "react-icons/io";
import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";

const Footer = () => {
  return (
    <footer>
      <div className='footer-wrapper pt-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 mx-auto'>
              <div className='row'>
              <div className='col-lg-4'>
              <div className='footer-left'>
                <Link to='/'>
                  <img src={logo} alt='logo' />
                </Link>
                
                <div className='email-detail'>
                  <p>Receive updates, hot deals, tutorials, discounts sent straignt in your inbox every month</p>
                  <div className='form-area'>
                    <input type="email" placeholder='Enter Your Email'/>
                    <button type="submit"><IoMdArrowForward /></button>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-8'>
              <div className='row'>
                <div className='col-lg-2'></div>
                <div className='col-lg-5'>
                  <div className='footer-menu'>
                    <h6>Usefull Links</h6>
                    <ul className='m-0 p-0'>
                      <li>
                        <Link to="">Site Map</Link>
                      </li>
                      <li>
                        <Link to="/privacy">Privacy and Policy</Link>
                      </li>
                      <li>
                        <Link to="/terms">Terms and Condition</Link>
                      </li>
                      
                    </ul>
                  </div>
                </div>
                <div className='col-lg-5'>
                  <div className='footer-menu contact-me'>
                    <h6>connect with Us</h6>
                    <Link to="tel:+91 9837038701" className='my-3'>+91 9837038701</Link>
                    <Link to="mailto:info@omniinfraheights.com">info@omniinfraheights.com</Link>
                    <div className='footer-social-icon'>
                      <ul className='m-0 p-0 d-flex align-items-center'>
                        <li>
                          <Link to="https://www.facebook.com/profile.php?id=100095252011374"><FaFacebookF /></Link>
                        </li>
                        <li>
                          <Link to="https://www.instagram.com/omniinfraheights/"><FaInstagram /></Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
              </div>
            </div>
            
          </div>
          <p className='copy-right text-center mb-0  mt-4 py-3'>&#169; Copyright Rhoomy. All Rights Reserved </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer