import React from 'react'
import bannerIn from "../../images/banner.jpg"
import Layout from '../../component/layout/Layout'

const Privacy = () => {
  return (
    <>
    <Layout>
        <section className='banner bg-property inner_banner'>
          <div className='inner-banner-image'>
            <img src={bannerIn} alt='banner-inn' />
            <div className='banner-content'>
              <div className='container'>
                <div className='row'>
                  <div className='col-lg-7 mx-auto'>
                    <h1 className='mb-3'>Privacy and Policy</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </section>
      <section className='about-content py-4'>
        <div className='container'>
          <h3>Privacy and Policy</h3>
          <p>Orient Vacations is a team of passionate travel innovators, who are on the mission to make travel accessible and affordable, thus protecting the freedom to enjoy excursion. Whether you are looking for trending destinations, enticing tour offers, or trip inspiration, you would never fall out of expectations if you choose us.</p>
          <p>Orient Vacations is a team of passionate travel innovators, who are on the mission to make travel accessible and affordable, thus protecting the freedom to enjoy excursion. Whether you are looking for trending destinations, enticing tour offers, or trip inspiration, you would never fall out of expectations if you choose us.</p>
          <p>Orient Vacations is a team of passionate travel innovators, who are on the mission to make travel accessible and affordable, thus protecting the freedom to enjoy excursion. Whether you are looking for trending destinations, enticing tour offers, or trip inspiration, you would never fall out of expectations if you choose us.</p>
          <p>Orient Vacations is a team of passionate travel innovators, who are on the mission to make travel accessible and affordable, thus protecting the freedom to enjoy excursion. Whether you are looking for trending destinations, enticing tour offers, or trip inspiration, you would never fall out of expectations if you choose us.</p>
          <p>Orient Vacations is a team of passionate travel innovators, who are on the mission to make travel accessible and affordable, thus protecting the freedom to enjoy excursion. Whether you are looking for trending destinations, enticing tour offers, or trip inspiration, you would never fall out of expectations if you choose us.</p>
          <p>Orient Vacations is a team of passionate travel innovators, who are on the mission to make travel accessible and affordable, thus protecting the freedom to enjoy excursion. Whether you are looking for trending destinations, enticing tour offers, or trip inspiration, you would never fall out of expectations if you choose us.</p>
          <p>Orient Vacations is a team of passionate travel innovators, who are on the mission to make travel accessible and affordable, thus protecting the freedom to enjoy excursion. Whether you are looking for trending destinations, enticing tour offers, or trip inspiration, you would never fall out of expectations if you choose us.</p>
          <p>Orient Vacations is a team of passionate travel innovators, who are on the mission to make travel accessible and affordable, thus protecting the freedom to enjoy excursion. Whether you are looking for trending destinations, enticing tour offers, or trip inspiration, you would never fall out of expectations if you choose us.</p>
        </div>
      </section>
      </Layout>
    </>
  )
}

export default Privacy