import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

// Thunk to fetch filtered data
export const fetchFilteredData = createAsyncThunk('data/fetchFilteredData',async ({ city, state }, thunkAPI) => {
    debugger
    try {
     const response = await axios.get(`https://omniinfraheights.com/admin/public/api/list-listing?city=${city}&state=${state}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const dataSlice = createSlice({
  name: 'searchfilter',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFilteredData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchFilteredData.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(fetchFilteredData.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default dataSlice.reducer;
