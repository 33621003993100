import React from 'react'
import { IoMdClose } from "react-icons/io";


const MapPopup = ({mapPopup, setMapPopup, singleData}) => {

    // console.log('singleData', singleData)

   const closeMapPopup = () =>{
    setMapPopup(false)
   }

//    const mapUrl = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d113579.63882943828!2d${singleData.longitude}!3d${singleData.latitude}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39740d857c2f41d9%3A0x784aef38a9523b42!2sAgra%2C%20Uttar%20Pradesh!5e0!3m2!1sen!2sin!4v1734009887818!5m2!1sen!2sin`;

//    document.getElementById('mapIframe').src = mapUrl;

//    const productdata = useSelector((state) => state.product.products?.data);

  return (
    <>
        <div className={mapPopup === true ? 'activemap mapPopup' : 'mapPopup'}>
            
           <div className='mapwrapper'>
                <button className='close-btn' onClick={closeMapPopup}>
                    <IoMdClose />
                </button>
                <div className='mapdata'>
                <iframe id="mapIframe" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
           </div>
        </div>
    </>
  )
}

export default MapPopup