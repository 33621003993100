import React, { useEffect, useRef, useState } from 'react'
import { IoIosSearch } from "react-icons/io";
import { RangSlider } from '../rangSlider/RangSlider';
import { SlArrowDown } from "react-icons/sl";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux'
import { bookingfilter, fetchSelectedData } from '../../redux/ProductSlice';
import { useNavigate } from 'react-router-dom';

const Searchflight = () => {

    const [isSelectOpen, setIsSelectOpen] = useState(false)
    const [selectItem, setSelectItem] = useState()

    
    const {products, isLoading} = useSelector((state) => state.product)
    const getproductitem = products?.data
    const cityName = [...new Set(getproductitem?.map((item)=> item.city))]
    const getstate = [...new Set(getproductitem?.map((item)=> item.state))]
    const getprice = [...new Set(getproductitem?.map((item)=> item.price))]

    
    const [filterList, setFilterList] = useState(cityName)
    const [filterListdata, setFilterListData] = useState(cityName)

    const [filterStateList, setFilterStateList] = useState(getstate)
    const [filterStateListdata, setFilterStateListData] = useState(getstate)

    console.log('filterListdata', filterListdata)


    const dispatch = useDispatch();
    const navigate = useNavigate()

    const dropdownRef = useRef(null);

    

    const [selectValue, setSelectValue] = useState({
        city:"", state:"", price:""
    })
 
    const handleSelect = (e) =>{
        // const keyword = e.target.value.toLowerCase()
        // const list = filterList.filter(item => item.toLowerCase().includes(keyword))

        // const list2 = list.filter((item, index)=> item.indexOf(item) === index);

        // setFilterListData(list2)
        // setFilterStateListData(list2)

        const {name, value} = e.target
        setSelectValue({...selectValue, [name]:value})   

        
    }

    const openSelect = () =>{
        setIsSelectOpen(!isSelectOpen)
    }
    const closeSelect = (cityname) =>{
        debugger
        setIsSelectOpen(false)
        setSelectValue({ ...selectValue, city: cityname });
        // setSelectedIndex(index)
    }
    const closeDropdown = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsSelectOpen(false);
        }
    };


    const [error, setError] = useState({})

    const handleSubmit = (e) =>{

        debugger
        e.preventDefault()

        const errorMsg = {}

        if(selectValue.city === '' || selectValue.city === 'null'){
            errorMsg.city = 'please select city'
        }
        if(selectValue.state === '' || selectValue.state === 'null'){
            errorMsg.state = 'please select state'
        }
        setError(errorMsg)

        if(Object.keys(errorMsg).length === 0){
            debugger
            dispatch(fetchSelectedData(selectValue))
            // toast.success("successfully search")
            navigate("/home/listing")
            
            setSelectValue({
                city:"", state:"", price:""
            });
           
        }
    }



    
    useEffect(() => {
        document.addEventListener('mousedown', closeDropdown);
        return () => {
          document.removeEventListener('mousedown', closeDropdown);
        };
      }, []);

  return (
    <>
          <div className='searchengen mt-2'>
            <form onSubmit={handleSubmit}>
                <div className='d-flex'>
                    <div className='search-box'>
                        <div className='form-item'>
                            <label>City</label>
                            <div className="destination-drop search_drop"  onClick={openSelect}>
                                {/* <input type='text' name='city' placeholder='Select city...' value={selectValue.city} onChange={handleSelect} /> */}
                                <span className="openSelect"> <SlArrowDown  /></span>
                                {/* {
                                    isSelectOpen === true && 
                                    <div className='select-drop' ref={dropdownRef}>
                                        <ul className='m-0 p-0'>
                                            {
                                                
                                                filterListdata?.map((item, index)=>{
                                                    return(
                                                        <>
                                                            <li key="index" onClick={()=> closeSelect(item)}>{item}</li>
                                                        </>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                }
                                 */}
                                <select id="city-select" name="city" value={selectValue.city} onChange={handleSelect}>

                                <option value="">Select a city</option>
                                    {
                                        
                                        cityName?.map((item, index)=>{
                                            return(
                                                <>
                                                    <option key="index">{item}</option>
                                                </>
                                            )
                                        })
                                    }
                                </select>
                                {error.city && <span className='red'>{error.city}</span>}
                            </div>
                        </div>
                    </div>
                    <div className='search-box'>
                        <div className='form-item'>
                            <label>Type</label>
                            <div className="search_drop" onClick={openSelect}>
                                {/* <input type='text' name='state' placeholder='Select city...' value={selectValue.state} onChange={handleSelect} /> */}
                                <span className="openSelect"> <SlArrowDown  /></span>
                                    {/* {
                                        isSelectOpen === true && 
                                        <div className='select-drop' ref={dropdownRef}>
                                            <ul className='m-0 p-0'>
                                                {
                                                    
                                                    filterStateListdata?.map((item, index)=>{
                                                        return(
                                                            <>
                                                                <li key="index" onClick={()=> closeSelect(item)}>{item}</li>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    } */}
                                <SlArrowDown />
                                <select id="state-select" name="state" value={selectValue.state} onChange={handleSelect}>
                                
                                    <option value="">Select a state</option>
                                    {
                                        getstate?.map((item, index)=>{
                                            return(
                                                <>
                                                    <option key="index">{item}</option>
                                                </>
                                            )
                                        })
                                    }
                                </select>
                                {error.state && <span className='red'>{error.state}</span>}
                            </div>
                        </div>
                    </div>
                    
                    <div className='search-box border-none'>
                        <div className='form-item'>
                            <label>Budget</label>
                            <div className="search_drop">
                                <SlArrowDown />
                                <select id="state-select" name="price" value={selectValue.price} onChange={handleSelect}>
                                
                                    <option value="">Select a price</option>
                                    {
                                        getprice?.map((item, index)=>{
                                            return(
                                                <>
                                                    <option key="index">{item}</option>
                                                </>
                                            )
                                        })
                                    }
                                    {/* <RangSlider  value={priceRange} setValue={setPriceRange} /> */}
                                </select>
                                {/* <input type='button' value="Property Type" />
                                <SlArrowDown />
                                <div className='select-drop'>
                                    <ul className='m-0 p-0'>
                                        <li>Agra</li>
                                        <li>mathura</li>
                                        <li>noida</li>
                                        <li>gurugram</li>
                                        <li>chennai</li>
                                    </ul>
                                </div> */}
                            </div>
                            
                        </div>
                    </div>
                    <div className='submit-btn'>
                        <button type='submit'><IoIosSearch /> Search</button>
                    </div>
                </div>
            </form>
          </div>
    </>
  )
}

export default Searchflight