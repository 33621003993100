import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getProject = createAsyncThunk('fetch/project', async()=>{
    const response = await fetch('https://omniinfraheights.com/admin/public/api/project-listing');
    const result = response.json()
    return result
})

const ProjectSlice = createSlice({
    name:'project',
    initialState:{
        isLoading:false,
        projects:[],
        isError:false,
    },
    extraReducers:(builder)=>{
        builder
        .addCase(getProject.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(getProject.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.projects = action.payload;
        })

        .addCase(getProject.rejected, (state, action)=>{
            state.isLoading = true;
            state.projects = action.payload;
        })
    }
})

export default ProjectSlice.reducer