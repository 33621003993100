import React, { useState } from 'react'
import bannerIn from "../../images/banner.jpg"
import Layout from '../../component/layout/Layout'
import { toast } from 'react-toastify'
import aboutimage from "../../images/project1.webp"
import aboutimage2 from "../../images/project2.webp"


const Contact = () => {

  const [userdata, setUserData] = useState({
    sourcetype:"Twitter", name:"", email:"", phone:"", address:""
  })

  const handleinput = (e) =>{
    const {name, value} = e.target
    setUserData({...userdata, [name]:value})   
}
console.log(userdata)

const [error, setError] = useState({})

const handleSubmit = async(e) =>{

  e.preventDefault()

  const errorMsg = {}
  // localStorage.setItem("user", JSON.stringify(userdata));
  // navigate('/')

 
  if(userdata.name === '' || userdata.name === 'null'){
      errorMsg.name = 'name is required'
  }
  if(userdata.email === '' || userdata.email === 'null'){
      errorMsg.email = 'email is required'
    }else if(!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userdata.email)){
      
      errorMsg.email = 'Please enter a valid email'
  }
  if(userdata.phone === '' || userdata.phone === 'null'){
      errorMsg.phone = 'phone number is required'
  }

  setError(errorMsg)

  if(Object.keys(errorMsg).length === 0){
    let toastId; // Define the toastId variable here
      try {
        toastId = toast("Submitting...", { autoClose: false });
          const response = await fetch('https://ithike.com/omni/dashboard/public/api/create-query', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(userdata)
          });
    
          if (!response.ok) {
            throw new Error('FAILED TO CREATE USER');
          }
    
          const getdata = await response.json();
          console.log('User created successfully:', getdata);
          toast.update(toastId, {
            render: "User created successfully!",
            type: "success",
            autoClose: 3000,
            isLoading: false
        });
          setUserData({
              sourcetype:"", name:"", email:"", phone:"", address:""
          });

        } catch (error) {
          console.error('Error creating user:', error.message);
          toast.update(toastId, {
            render: `Error: ${error.message}`,
            type: "error",
            autoClose: 3000,
            isLoading: false
        });
        }
    }else {
      // Show validation errors as toast
      Object.values(errorMsg).forEach(err => {
          toast.error(err);
      });
  }
}


  return (
    <>
    <Layout>
        <section className='banner bg-property inner_banner'>
          <div className='inner-banner-image'>
            <img src={bannerIn} alt='banner-inn' />
            <div className='banner-content'>
              <div className='container'>
                <div className='row'>
                  <div className='col-lg-7 mx-auto'>
                    <h1 className='mb-3'>Contact Us</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </section>
      <section className='about-content py-4'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='contact-form'>
                  <div className='register'>
                    <div className='reg-inn'>
                        <h2>Contact Us</h2>
                        <form onSubmit={handleSubmit}>
                            <div className='formgroup'>
                                <label htmlFor='name'>Name</label>
                                <input type='text' onChange={handleinput} name='name' value={userdata.name} />
                                {error.name && <span className='red'>{error.name}</span>}
                            </div>
                            <div className='formgroup'>
                                <label htmlFor='email'>Email</label>
                                <input type='email' onChange={handleinput} name='email' value={userdata.email} />
                                {error.email && <span className='red'>{error.email}</span>}
                            </div>
                            <div className='formgroup'>
                                <label htmlFor='phone'>Phone</label>
                                <input type='phone' onChange={handleinput} name='phone' value={userdata.phone} />
                                {error.phone && <span className='red'>{error.phone}</span>}
                            </div>
                            <div className='formgroup'>
                                <label htmlFor='address'>Address</label>
                                <textarea name="address" onChange={handleinput} value={userdata.address} rows="6" cols="20"></textarea>
                            </div>
                            <div className='submit-btn d-flex justify-content-center'>
                                <button type="submit">Submit</button>
                            </div>
                        </form>
                    </div>
                  </div>
              </div>
            </div>
            <div className='col-lg-6'>
            <div className='contact_info pt-4'>
              <h6><strong>Location - </strong>Agra (Head Office)</h6>
              <p><strong>Address - </strong> 9, Suraj complex , Sadar Bazar Agra, U.P. 282001</p>
              <h6><strong>Phone - </strong><a href='tel:+91 9837038701'>+91 9837038701</a></h6>
              <h6><strong>Email - </strong> <a href='mailto:info@omniinfraheights.com'>info@omniinfraheights.com</a></h6>
              <p className='mt-3'><strong>No.</strong>  UPRERAAGT15736</p>
            </div>
              <div className='img-item'>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d224096.9800953165!2d76.92842322685054!3d28.644285209616285!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd5b347eb62d%3A0x37205b715389640!2sDelhi!5e0!3m2!1sen!2sin!4v1726753091465!5m2!1sen!2sin" width="100%" height="500" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
      </Layout>
    </>
  )
}

export default Contact