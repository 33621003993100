
import { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import HomePage from './pages/home/HomePage'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap.min.js'
import './styles/style.css'
import Listing from './pages/listing/Listing'
import Detail from './pages/detail/Detail.js'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCategory } from './redux/CategorySlice.js'
import Register from './component/register/Register.js'
import { fetchProduct } from './redux/ProductSlice.js'
import About from './pages/about/About.jsx'
import Contact from './pages/contact/Contact.js'
import Privacy from './pages/privacypolicy/Privacy.js'
import Termscondition from './pages/termsandcondition/Termscondition.js'
import Notfoundpage from './pages/pagenotfound/Notfoundpage.js'
import ScrollToTop from './component/scrolltop/ScrollToTop.js'
import { startLoading, stopLoading } from './redux/LoaderSlice.js'
import SiteLoader from './component/siteloader/SiteLoader.js'
import { getbuilder } from './redux/BuilderSlice.js'
import { getProject } from './redux/ProjectSlice.js'




const App = () => {

  const location = useLocation();
  const dispatch = useDispatch()
  const isLoading = useSelector((state) => state.loader.isLoading);

  useEffect(()=>{
    
    dispatch(fetchProduct())
    dispatch(fetchCategory())
    dispatch(getbuilder())
    dispatch(getProject())
  },[dispatch])

  useEffect(() => {
    dispatch(startLoading());

    // Mimic an async operation, like fetching data or rendering components.
    const timeoutId = setTimeout(() => {
      dispatch(stopLoading());
    }, 1000); // You can adjust the duration or trigger based on when data is fetched.

    return () => clearTimeout(timeoutId);
  }, [location, dispatch]);



  return (
    <>
    <div>
      {isLoading && <SiteLoader />}
      {/* Your routes and other components go here */}
    </div>
      <ScrollToTop />
        <Register/>
        <Routes>
          <Route  exact={true} path='/' element={<HomePage  />} /> 
          
          <Route  exact={true} path='/home/listing/:city' element={<Listing  />} /> 
          <Route  exact={true} path='/home/listing/:id' element={<Listing  />} /> 

          <Route  exact={true} path='/home/detail/:id' element={<Detail/>} />

          <Route  path='/home/listing' element={<Listing/>} /> 
          <Route  exact={true} path='/home/detail/:id' element={<Detail/>} /> 

          <Route exact={true} path='/detail' element={<Detail/>} /> 
          <Route exact={true} path='/about' element={<About/>} /> 
          <Route exact={true} path='/contact' element={<Contact/>} /> 
          <Route exact={true} path='/privacy' element={<Privacy/>} /> 
          <Route exact={true} path='/terms' element={<Termscondition/>} />
          {/* Catch-all route for undefined paths */}
          <Route path="*" element={<Notfoundpage />} />
          
        </Routes>
    </>
  )
}

export default App