import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import Store from './redux/Store';
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.createRoot(document.getElementById('root')).render(
  <BrowserRouter>
  <ToastContainer theme="dark" position="top-right" autoClose={2000} closeOnClick pauseOnHover={false} />
    <Provider store={Store}>
      <App />
    </Provider>
  </BrowserRouter>
)