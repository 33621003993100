import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const fetchCategory = createAsyncThunk('category', async()=>{
    const res = await fetch('https://omniinfraheights.com/admin/public/api/categories')
    const data = await res.json();
    return data
})

const CategorySlice = createSlice({
    name:'categories',
    initialState:{
        isLoading:false,
        categories:[],
        isError:false,
    },
    reducers:{},
    extraReducers:(builder) =>{
        builder.addCase(fetchCategory.pending, (state, action)=>{
            state.isLoading = true
        })
        builder.addCase(fetchCategory.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.categories = action.payload
        })
        builder.addCase(fetchCategory.rejected, (state, action)=>{
            state.isError = true;
            state.categories = action.payload
        })
    }
})


export default CategorySlice.reducer