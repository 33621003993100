import React from 'react'


const TestimonialCard = () => {
  return (
    <>
        <div className='testcard'>
              
            <p className='mb-0'>
            From 2020 to 2024, real estate has out performed gold by 35%.
            </p>
            {/* <div className='client-image'>
              <img src={user} alt='user' />
            </div>
            <div className='about-client border'>
                <h6>Jhon Doe</h6>
                <p className='mb-0'>Owner, Digital Agency</p>
            </div> */}
        </div>
    </>
  )
}

export default TestimonialCard