import Footer from "../footer/Footer"
import Header from "../header/Header"
import '../../styles/style.css'


const Layout = ({children}) => {



  return (
   <>
       <Header />
        {
            children
        }
        <Footer/>
   </>
  )
}

export default Layout