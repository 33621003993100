import React, {useRef } from 'react'
import Searchflight from '../../component/searchflight/Searchflight'
import { Navigation, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { GoArrowLeft } from "react-icons/go";
import { GoArrowRight } from "react-icons/go";
import TestimonialCard from '../../component/testimonial/TestimonialCard'
import Layout from '../../component/layout/Layout';
import { Link } from 'react-router-dom';
import agra from '../../images/agra.jpg'
import delhi from '../../images/delhi.jpg'
import noida from '../../images/noida.jpg'
import { ImQuotesLeft } from "react-icons/im";
import { ImQuotesRight } from "react-icons/im";
import BlogCard from '../../component/blog/BlogCard';
import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { MdLocalPhone } from "react-icons/md";
import { MdMailOutline } from "react-icons/md";
import { useSelector } from 'react-redux';
import { showPopup } from '../../redux/PopupSlice';
import { useDispatch } from 'react-redux';


const HomePage = () => {

const dispatch = useDispatch();

const getCategory = useSelector((state)=> state.category.categories?.data)

const productdata = useSelector((state) => state.product.products?.data);

    const handleInquiryClick = () => {
      dispatch(showPopup());
    };

    const swiper1Ref = useRef(null);
    const swiper2Ref = useRef(null);
    const swiper3Ref = useRef(null);

    
    // Function to navigate specific swiper
    const slideNextSwiper1 = () => {
      if (swiper1Ref.current && swiper1Ref.current.slideNext) {
        swiper1Ref.current.slideNext();
      }
    };
    const slidePrevSwiper1 = () => {
      if (swiper1Ref.current && swiper1Ref.current.slidePrev) {
        swiper1Ref.current.slidePrev();
      }
    };
    
    const slideNextSwiper2 = () => {
      if (swiper2Ref.current && swiper2Ref.current.slideNext) {
        swiper2Ref.current.slideNext();
      }
    };
    const slidePrevSwiper2 = () => {
      if (swiper2Ref.current && swiper2Ref.current.slidePrev) {
        swiper2Ref.current.slidePrev();
      }
    };
    const slideNextSwiper3 = () => {
      if (swiper3Ref.current && swiper3Ref.current.slideNext) {
        swiper3Ref.current.slideNext();
      }
    };
    const slidePrevSwiper3 = () => {
      if (swiper3Ref.current && swiper3Ref.current.slidePrev) {
        swiper3Ref.current.slidePrev();
      }
    };
  return (  
     <Layout>
     
      <section className='banner bg-property'>
        <div className='banner-content'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-7 mx-auto'>
                <h1 className='mb-3'>Discover Your Dream Home</h1>
                <p>Find new and featured property in your city</p>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-11 mx-auto'>
                <div className='flight-search'>
                  <Searchflight/>
                </div>
              </div>
            </div>
          </div>
          <div className='follow'>
            <h6 className='mb-3'>Follow</h6>
            <div className='footer-social-icon'>
              <ul className='m-0 p-0'>
                <li>
                  <Link to="https://www.facebook.com/profile.php?id=100095252011374"><FaFacebookF /></Link>
                </li>
                <li>
                  <Link to="https://www.instagram.com/omniinfraheights/"><FaInstagram /></Link>
                </li>
              </ul>
            </div>
            <div className='footer-social-icon call'>
            <h6 className='mb-3'>Call</h6>
              <ul className='m-0 p-0'>
                <li>
                  <Link to="tel:+91 9837038701"> <MdLocalPhone /></Link>
                </li>
              </ul>
            </div>
            
          </div>
        </div>
      
      </section>
      <section className='category locatio-groupt mt-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 mx-auto'>
              <h2 className='mb-3'>Categories</h2>
              <div className='category-slider relative'>
                <Swiper
                    modules={[Navigation]}
                    onSwiper={(swiper) => (swiper1Ref.current = swiper)}
                    spaceBetween={20}
                    slidesPerView={4}
                    loop={true}
                    speed={1000}

                    breakpoints={{
                      // when window width is >= 640px
                      320: {
                        slidesPerView: 1, // 2 items on mobile
                      },
                      // when window width is >= 768px
                      768: {
                        slidesPerView: 2, // 3 items on tablets and larger screens
                      },
                      992: {
                        slidesPerView: 3, // 3 items on tablets and larger screens
                      },
                      1025: {
                        slidesPerView: 4, // 3 items on tablets and larger screens
                      },
                    }}
                    
                    >

                    {
                      getCategory?.map((item, index)=>{

                        return(
                          <SwiperSlide key={index}>
                            <div className='category-card mb-3'>
                              <Link to={`/home/listing/${`2-`+ item.category_id}`}>
                                <div className='card-image'>
                                  <img src={item && item.cat_icon ? `https://omniinfraheights.com/admin/public/${item.cat_icon}` : 'fallback-image-url'} alt='appartment' />
                                    {/* <img src={`https://ithike.com/omni/dashboard/public/${item.cat_icon}`} alt='appartment' /> */}
                                    <div className='card-title'>
                                      <span>{item.cat_name}</span>
                                    </div>
                                </div>
                              </Link>
                            </div>
                          </SwiperSlide>
                        )
                      })
                    }
                    
                        
                </Swiper>
                <div className='swiper-nav'>
                    
                  <button className="arrow-left arrow" onClick={slideNextSwiper1}>
                    <GoArrowRight />
                  </button>
                  <button className="arrow-right arrow" onClick={slidePrevSwiper1}>
                    <GoArrowLeft />
                  </button>
                    {/* <button className="arrow-right arrow"><GoArrowLeft /></button>
                    <button className="arrow-left arrow"><GoArrowRight /></button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='popular-location locatio-groupt mt-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 mx-auto'>
              <h2 className='mb-3 d-flex align-items-center justify-content-between'>Popular Locations <Link to="/home/listing" className='view-all'>View All</Link></h2>
              <div className='category-slider relative'>
                <Swiper
                    modules={[Navigation]}
                    spaceBetween={20}
                    slidesPerView={4}
                    loop={true}
                    speed={1000}
                    onSwiper={(swiper) => (swiper2Ref.current = swiper)}

                    breakpoints={{
                      // when window width is >= 640px
                      320: {
                        slidesPerView: 1, // 2 items on mobile
                      },
                      // when window width is >= 768px
                      768: {
                        slidesPerView: 2, // 3 items on tablets and larger screens
                      },
                      992: {
                        slidesPerView: 3, // 3 items on tablets and larger screens
                      },
                      1025: {
                        slidesPerView: 4, // 3 items on tablets and larger screens
                      },
                    }}
                    >
                    <SwiperSlide>
                      <div className='category-card mb-3'>
                          <Link to=''>
                            <div className='card-image'>
                                <img src={agra} alt='appartment' />
                                <div className='card-title'>
                                  <span>Agra</span>
                                </div>
                            </div>
                          </Link>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='category-card mb-3'>
                          <Link to="">
                            <div className='card-image'>
                                <img src={delhi} alt='appartment' />
                                <div className='card-title'>
                                  <span>Delhi</span>
                                </div>
                            </div>
                          </Link>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='category-card mb-3'>
                          <Link to="">
                            <div className='card-image'>
                                <img src={noida} alt='appartment' />
                                <div className='card-title'>
                                  <span>Noida</span>
                                </div>
                            </div>
                          </Link>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='category-card mb-3'>
                          <Link to=''>
                            <div className='card-image'>
                                <img src={agra} alt='appartment' />
                                <div className='card-title'>
                                  <span>Agra</span>
                                </div>
                            </div>
                          </Link>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='category-card mb-3'>
                          <Link to="">
                            <div className='card-image'>
                                <img src={delhi} alt='appartment' />
                                <div className='card-title'>
                                  <span>Delhi</span>
                                </div>
                            </div>
                          </Link>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='category-card mb-3'>
                          <Link to="">
                            <div className='card-image'>
                                <img src={noida} alt='appartment' />
                                <div className='card-title'>
                                  <span>Noida</span>
                                </div>
                            </div>
                          </Link>
                      </div>
                    </SwiperSlide>
                        
                </Swiper>
                <div className='swiper-nav'>
                <button className="arrow-left arrow" onClick={slideNextSwiper2}>
                    <GoArrowRight />
                  </button>
                  <button className="arrow-right arrow" onClick={slidePrevSwiper2}>
                    <GoArrowLeft />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='testimonial mt-5 py-5'>
        <div className='container'>
         
          <div className='row'>
            <div className='col-lg-12 mx-auto'>
            <div className='testimonial-slider relative'>
              <h2>Did You Know?</h2>

            <Swiper
                  modules={[Navigation, Autoplay]}
                  spaceBetween={20}
                  slidesPerView={1}
                  loop={true}
                  speed={1200}
                  autoplay={true}
                  
                  navigation={{ nextE4: ".arrow-left", prevE5: ".arrow-right" }}
                  >
                  <SwiperSlide>
                      <TestimonialCard/>
                  </SwiperSlide>
                  <SwiperSlide>
                      <TestimonialCard/>
                  </SwiperSlide>
                  <SwiperSlide>
                      <TestimonialCard/>
                  </SwiperSlide>
                  <SwiperSlide>
                      <TestimonialCard/>
                  </SwiperSlide>
                  
                  
              </Swiper>
              
          </div>
            </div>
          </div>
        </div>
      </section>
      <section className='recommanded-properties locatio-groupt mt-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 mx-auto'>
              <h2 className='mb-3 d-flex align-items-center justify-content-between'>Recommended Properties <Link to="/home/listing" className='view-all'>View All</Link></h2>
              <div className='category-slider relative'>
                <Swiper
                    modules={[Navigation]}
                    spaceBetween={20}
                    slidesPerView={4}
                    loop={true}
                    speed={2000}
                   
                    onSwiper={(swiper) => (swiper3Ref.current = swiper)}

                    breakpoints={{
                      // when window width is >= 640px
                      320: {
                        slidesPerView: 1, // 2 items on mobile
                      },
                      // when window width is >= 768px
                      768: {
                        slidesPerView: 2, // 3 items on tablets and larger screens
                      },
                      992: {
                        slidesPerView: 3, // 3 items on tablets and larger screens
                      },
                      1025: {
                        slidesPerView: 4, // 3 items on tablets and larger screens
                      },
                    }}
                    >

                    {
                      productdata &&
                      productdata?.map((productItem, index)=>{
                        console.log('productItem', productItem)
                        return(
                          <>
                            <SwiperSlide key={index}>
                              <div className='place-card'>
                                <div className='place-image relative'>
                                  {/* <img src={productItem.image[0]} alt='card' /> */}
                                  <img src={productItem && productItem.image ? `https://omniinfraheights.com/admin/public/uploads/${productItem.image[0]}` : 'fallback-image-url'} alt='appartment' />
                                  <span className='appart-title'>{productItem.metatitle}</span>
                                </div>
                                <div className='place-area-content'>
                                    <h4>{productItem.title}</h4>
                                    <ul className='m-0 p-0 d-flex align-items-center'>
                                        <li>{productItem.unit_type}</li>
                                        <li>{productItem.size}</li>
                                    </ul>
                                    <p className='mb-2 mt-2'>{productItem.address}</p>
                                    <p className='m-0'><strong>₹ {productItem.price}</strong> onwards</p>
                                </div>
                                <div className='place-buttons d-flex align-items-center justify-content-between mt-3'>
                                    <Link to="" className='enquiry-btn' onClick={handleInquiryClick}><MdMailOutline /> Enquiry</Link>
                                    <Link to={`/home/detail/${productItem.listingID}`}>View Details</Link>
                                </div>
                              </div>
                            </SwiperSlide>
                          </>
                        )
                      })
                    } 
                </Swiper>
                <div className='swiper-nav'>
                  <button className="arrow-left arrow" onClick={slideNextSwiper3}>
                    <GoArrowRight />
                  </button>
                  <button className="arrow-right arrow" onClick={slidePrevSwiper3}>
                    <GoArrowLeft />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='popular-location locatio-groupt mt-5 py-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 mx-auto'>
             
              <div className='category-slider relative'>
                <Swiper
                    modules={[Navigation, Autoplay]}
                    spaceBetween={20}
                    slidesPerView={1}
                    loop={true}
                    speed={2000}
                    autoplay={false}
                        
                    navigation={{ nextEl: ".arrow-left", prevEl: ".arrow-right" }}
                    >
                    <SwiperSlide>
                      <div className='desc test-cont-slide'>
                          <div className='d-flex align-items-center justify-content-between'>
                            <span className='quotes-left'><ImQuotesLeft /></span>
                            <span className='quotes-right'><ImQuotesRight /></span>
                          </div>
                          <p className='mb-0 pb-0'>Real estate is a long-term game. Don't try to get rich quick. Instead, focus on buying properties that will generate income and appreciate over time.
                          </p>
                          <span className='owener'>- Warren Buffett, business magnate</span>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='desc test-cont-slide'>
                          <div className='d-flex align-items-center justify-content-between'>
                            <span className='quotes-left'><ImQuotesLeft /></span>
                            <span className='quotes-right'><ImQuotesRight /></span>
                          </div>
                          <p className='mb-0 pb-0'>Real estate is a long-term game. Don't try to get rich quick. Instead, focus on buying properties that will generate income and appreciate over time.
                          </p>
                          <span className='owener'>- Warren Buffett, business magnate</span>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className='desc test-cont-slide'>
                          <div className='d-flex align-items-center justify-content-between'>
                            <span className='quotes-left'><ImQuotesLeft /></span>
                            <span className='quotes-right'><ImQuotesRight /></span>
                          </div>
                          <p className='mb-0 pb-0'>Real estate is a long-term game. Don't try to get rich quick. Instead, focus on buying properties that will generate income and appreciate over time.
                          </p>
                          <span className='owener'>- Warren Buffett, business magnate</span>
                      </div>
                    </SwiperSlide>
                    
                        
                </Swiper>
                <div className='swiper-nav'>
                    <button className="arrow-right arrow"><GoArrowLeft /></button>
                    <button className="arrow-left arrow"><GoArrowRight /></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <section className='recommanded-properties locatio-groupt mt-5 mb-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 mx-auto'>
              <h2 className='mb-3 d-flex align-items-center justify-content-between'>Blogs <Link to="/home/listing" className='view-all'>View All</Link></h2>
              <div className='category-slider relative'>
                <Swiper
                    modules={[Navigation, Autoplay]}
                    spaceBetween={20}
                    slidesPerView={4}
                    loop={true}
                    speed={1000}
                    autoplay={false}
                    navigation={{ nextEl: ".arrow-left", prevEl: ".arrow-right" }}
                    breakpoints={{
                      // when window width is >= 640px
                      320: {
                        slidesPerView: 1, // 2 items on mobile
                      },
                      // when window width is >= 768px
                      768: {
                        slidesPerView: 2, // 3 items on tablets and larger screens
                      },
                      992: {
                        slidesPerView: 3, // 3 items on tablets and larger screens
                      },
                      1025: {
                        slidesPerView: 4, // 3 items on tablets and larger screens
                      },
                    }}
                    >
                    <SwiperSlide>
                      <BlogCard/>
                    </SwiperSlide>
                    <SwiperSlide>
                      <BlogCard/>
                    </SwiperSlide>
                    <SwiperSlide>
                      <BlogCard/>
                    </SwiperSlide>
                    <SwiperSlide>
                      <BlogCard/>
                    </SwiperSlide>
                    <SwiperSlide>
                      <BlogCard/>
                    </SwiperSlide>
                    <SwiperSlide>
                      <BlogCard/>
                    </SwiperSlide>
                    
    
                </Swiper>
                <div className='swiper-nav'>
                    <button className="arrow-right arrow"><GoArrowLeft /></button>
                    <button className="arrow-left arrow"><GoArrowRight /></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
     </Layout>
   
  )
}

export default HomePage