import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import logo from '../../images/logo.png'
import { MdKeyboardArrowDown } from "react-icons/md";
import Register from '../register/Register';
import { useDispatch, useSelector } from 'react-redux';
import { fetchcityProduct } from '../../redux/ProductSlice';
import { IoMdClose } from "react-icons/io";
import { HiOutlineBars3 } from "react-icons/hi2";

const Header = () => {
    const dispatch = useDispatch()
    const [activeIndex, setActiveIndex] = useState(null);
    const [scrolled, setScrolled] = useState(false);
    const [activeCity, setActiveCity] = useState('');

    const productdata = useSelector((state) => state.product.products?.data);
    const builderData = useSelector((state) => state.owner.builders?.data);
    const projectData = useSelector((state) => state.proj.projects?.data);
   
    const cityName = [...new Set(productdata?.map((item)=> item.city))]

    const navRef = useRef()

    const showNavbar = () =>{
        navRef.current.classList.toggle("responsive_nav")
    }

    useEffect(() => {
        const savedCity = localStorage.getItem('activeCity');
        if (savedCity) {
            setActiveCity(savedCity);
        }
    }, []);
    

    const handleClick = (event, index) => {
       // Toggle the active state: if clicked again, remove active
       event.preventDefault()
        if (activeIndex === index) {
            setActiveIndex(null); // Remove active state
        } else {
            setActiveIndex(index); // Set active state
        }
      };

    useEffect(() => {
        const handleScroll = () => {
          const offset = window.scrollY;
          if (offset > 100) {
            setScrolled(true);
          } else {
            setScrolled(false);
          }
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

  return (
    <>
    {/* <Register /> */}
    <header className={scrolled ? 'fixed custom-header' : 'custom-header'}>
        
        <div className='container-fluid'>
            <div className='row d-flex align-items-center justify-content-between'>
                <div className='col-lg-11'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <div className='logo'>
                            <Link to="/">
                                <img src={logo} alt='logo' />
                            </Link>
                        </div>
                        
                        <nav ref={navRef}>
                            <ul className='p-0 m-0 d-flex align-items-center'>
                                <li>
                                    <Link to='/'>Home</Link>
                                </li>
                                <li className={activeIndex === 0 ? "active" : ""}>
                                    <Link to="/" onClick={(e)=>handleClick(e,0)} >City <MdKeyboardArrowDown /></Link>
                                    <div className='dropdownMenu'>
                                    <ul className='m-0 p-0'>
                                        {
                                            cityName?.map((menuItem, index)=>{
                                                return(
                                                    <>
                                                        <li key={index} className={menuItem === activeCity ? 'active' : ''}>
                                                            <Link to={`/home/listing/${`0-` + menuItem.toLowerCase()}`} onClick={showNavbar}>{menuItem}</Link>
                                                        </li>
                                                    </>
                                                )
                                            })
                                        }
                                        </ul>
                                    </div>
                                </li>
                                <li className={activeIndex === 1 ? "active" : ""}>
                                    <Link to="/" onClick={(e)=>handleClick(e,1)}>Builder <MdKeyboardArrowDown /></Link>
                                    <div className='dropdownMenu'>
                                        <ul className='m-0 p-0'>
                                        {
                                            builderData &&
                                            builderData.map((item)=>{
                                                return(
                                                    <li key={item.id}>
                                                        <Link to={`/home/listing/${`3-`+item.id}`}>{item.builder_name}</Link>
                                                    </li>
                                                )
                                            })
                                        }
                                            
                                            
                                        </ul>
                                    </div>
                                </li>
                                <li className={activeIndex === 2 ? "active" : ""}>
                                    <Link to="/" onClick={(e)=>handleClick(e,2)}>Projects <MdKeyboardArrowDown /></Link>
                                    <div className='dropdownMenu'>
                                        <ul className='m-0 p-0'>
                                        {
                                            projectData &&
                                            projectData.map((item)=>{
                                                return(
                                                    <li key={item.id}>
                                                        <Link to={`/home/listing/${`1-`+item.projects_id}`} >{item.project_name}</Link>
                                                    </li>
                                                )
                                            })
                                        }
                                            
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <Link to="/about" >About Us</Link>
                                </li>
                               
                                <li>
                                    <Link to="/contact" >Contact Us</Link>
                                </li>
                            </ul>
                            <button className='nav-btn nav-close-btn' onClick={showNavbar}><IoMdClose /></button>    
                        </nav>
                        <button className='nav-btn' onClick={showNavbar}>
                            <HiOutlineBars3 />
                        </button>
                       
                    </div>
                </div>
                {/* <div className='col-lg-2'>
                    <div className='right-header d-flex justify-content-end'>
                        <ul className='d-flex align-items-center m-0 p-0'>
                            
                            <li>
                                <Link to='' className='add-prop custom-btn'>
                                    <GoPlusCircle /> Send Query
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div> */}
            </div>
        </div>
    </header>
    
    </>
  )
}

export default Header