import { configureStore } from "@reduxjs/toolkit";
import CategorySlice from "./CategorySlice";
import popupReducer from './PopupSlice';
import ProductReducer from './ProductSlice'
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from 'redux-persist';
import {combineReducers} from "@reduxjs/toolkit"
import searchReducer from "./SearchSlice"
import loaderReducer from './LoaderSlice'
import builderReducer from './BuilderSlice'
import projectReducer from './ProjectSlice'


const Store = configureStore({
    reducer:{
    category:CategorySlice,
    popup: popupReducer,
    product:ProductReducer,  
    searchfilter:searchReducer,   
    loader:loaderReducer,
    owner:builderReducer,
    proj:projectReducer,
    }
    
})

// export const persistor = persistStore(Store);
export default Store