import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { hidePopup } from '../../redux/PopupSlice'
import { IoMdClose } from "react-icons/io";
import { toast } from 'react-toastify';

const Register = () => {
    const dispatch = useDispatch();

    const isVisible = useSelector((state) => state.popup.isVisible);
    
    const closeModelpopup = () =>{
        dispatch(hidePopup())
    }

    const [userdata, setUserData] = useState({
        sourcetype:"contact", name:"", email:"", phone:"", city:"", address:""
    })

    const handleinput = (e) =>{
        const {name, value} = e.target
        setUserData({...userdata, [name]:value})   
    }

    
    const [error, setError] = useState({})

    const handleSubmit = async(e) =>{
        e.preventDefault()

        const errorMsg = {}
        // localStorage.setItem("user", JSON.stringify(userdata));
        // navigate('/')

        if(userdata.sourcetype === '' || userdata.sourcetype === 'null'){
            errorMsg.sourcetype = 'sourcetype select is required'
        }
        if(userdata.name === '' || userdata.name === 'null'){
            errorMsg.name = 'name is required'
        }
        if(userdata.email === '' || userdata.email === 'null'){
            errorMsg.email = 'email is required'
          }else if(!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userdata.email)){
            
            errorMsg.email = 'Please enter a valid email'
        }
        if(userdata.phone === '' || userdata.phone === 'null'){
            errorMsg.phone = 'phone number is required'
        }

        setError(errorMsg)

        if(Object.keys(errorMsg).length === 0){
            let toastId; // Define the toastId variable here
            try {
                toastId = toast("Submitting...", { autoClose: false });
                const response = await fetch('https://omniinfraheights.com/admin/public/api/create-query', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(userdata)
                });
          
                if (!response.ok) {
                  throw new Error('FAILED TO CREATE USER');
                }
          
                const getdata = await response.json();
                    console.log('User created successfully:', getdata);
                    // toast.success("Account created")
                    dispatch(hidePopup())
                    toast.update(toastId, {
                        render: "User created successfully!",
                        type: "success",
                        autoClose: 3000,
                        isLoading: false
                    });
                
                setUserData({
                    sourcetype:"", name:"", email:"", phone:"", city:"", address:""
                });
    
              } catch (error) {
                console.log('Error creating user:', error.message);
                toast.update(toastId, {
                    render: `Error: ${error.message}`,
                    type: "error",
                    autoClose: 3000,
                    isLoading: false
                });
              }
          }else {
            // Show validation errors as toast
            Object.values(errorMsg).forEach(err => {
                toast.error(err);
            });
        }
    }


   console.log()

  return (
    <>
         <div className={isVisible ? "active register" : "register"}>
            <div className='reg-inn'>
                <button className='close-btn' onClick={closeModelpopup}>
                    <IoMdClose />
                </button>
                <h1 className='text-center'>Register</h1>
                <form onSubmit={handleSubmit}>
                    <div className='formgroup'>
                        <label htmlFor='sourcetype'>Sourcetype</label>
                        <select name="sourcetype" onChange={handleinput} value={userdata.sourcetype}>
                            <option >Facebook</option>
                            <option >Twitter</option>
                            <option >Instagram</option>
                            <option >Google</option>
                        </select>
                        {error.sourcetype && <span className='red'>{error.sourcetype}</span>}
                    </div>
                    <div className='formgroup'>
                        <label htmlFor='name'>Name</label>
                        <input type='text' onChange={handleinput} name='name' value={userdata.name} />
                        {error.name && <span className='red'>{error.name}</span>}
                    </div>
                    <div className='formgroup'>
                        <label htmlFor='email'>Email</label>
                        <input type='email' onChange={handleinput} name='email' value={userdata.email} />
                        {error.email && <span className='red'>{error.email}</span>}
                    </div>
                    <div className='formgroup'>
                        <label htmlFor='phone'>Phone</label>
                        <input type='phone' onChange={handleinput} name='phone' value={userdata.phone} />
                        {error.phone && <span className='red'>{error.phone}</span>}
                    </div>
                    <div className='formgroup'>
                        <label htmlFor='city'>City</label>
                        <select name="city" onChange={handleinput} value={userdata.city}>
                            <option>Agra</option>
                            <option>Mathura</option>
                            <option>Noida</option>
                            <option>Gurugram</option>
                        </select>
                        {error.city && <span className='red'>{error.city}</span>}
                    </div>
                    <div className='formgroup'>
                        <label htmlFor='address'>Address</label>
                        <textarea name="address" onChange={handleinput} value={userdata.address} rows="2" cols="20"></textarea>
                    </div>
                    <div className='submit-btn d-flex justify-content-center'>
                        <button type="submit">Submit</button>
                    </div>
                </form>
            </div>
        </div>

    </>
  )
}

export default Register