import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getbuilder = createAsyncThunk('fetch/builder', async()=>{
    const response = await fetch('https://omniinfraheights.com/admin/public/api/builders');
    const result = response.json()
    return result
})

const BuilderSlice = createSlice({
    name:'builder',
    initialState:{
        isLoading:false,
        builders:[],
        isError:false,
    },
    extraReducers:(builder)=>{
        builder
        .addCase(getbuilder.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(getbuilder.fulfilled, (state, action)=>{
            state.isLoading = false;
            state.builders = action.payload;
        })

        .addCase(getbuilder.rejected, (state, action)=>{
            state.isLoading = true;
            state.builders = action.payload;
        })
    }
})

export default BuilderSlice.reducer